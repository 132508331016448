<template>
  <div>
    <div>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <Navbar></Navbar>
          <div class="card">
          <div class="card-content">
            <div class="block">
              <h2 class="title is-4">Study 家教平台</h2>
              <p>
                思达迪（STUDY）为孩子们提供合适的大学生辅导老师
              </p>
              &nbsp;&nbsp;公众号
              <img class="image is-128x128" src="../assets/studyscan.jpg">
              &nbsp;&nbsp;<a style="color:info" v-on:click="openstusy">前往平台</a>
              &nbsp;&nbsp;<a style="color:info" v-on:click="openstusyFront">前端代码</a>
              &nbsp;&nbsp;<a style="color:info" v-on:click="openstusyBackend">后端代码</a>
            </div>

            <div class="block">
              <h2 class="title is-4">紫金矿业试题生成模块</h2>
              <a style="color:info" v-on:click="gotoTimuPage">题目生成展示</a>
            </div>

            <div class="block">
              <h2 class="title is-4">聚合支付</h2>
              <p>该聚合支付将支付宝和微信支付api做再次封装，使得接口方便实用。</p>
              <a style="color:info" v-on:click="openpaysys">使用文档</a>
              &nbsp;&nbsp;<a style="color:info" v-on:click="openpaycode">代码</a>
            </div>

            <div class="block">
              <h2 class="title is-4">厦大智慧商圈微信小程序</h2>
              <p>用户可以发布店铺信息,发布商品信息</p>
              &nbsp;&nbsp;<a style="color:info" v-on:click="openxumstore">代码</a>
            </div>

          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
export default {
  name: "Projects",
  components: {
    Navbar,
  },
  methods:{
    openstusyBackend(){
 window.open('https://github.com/yajiewen/StudyBackend')
    },
    openstusyFront(){
 window.open('https://github.com/yajiewen/StudyFront')
    },
     openpaycode(){
 window.open('https://github.com/yajiewen/xumpay')
    },
    openxumstore(){
 window.open('https://github.com/yajiewen/xmu-xiaochengxu')
    },
    openpaysys(){
      window.open('../../paysys.html')
    },
    openstusy(){
      window.open('http://kidtut.net')
    },
    gotoTimuPage(){
      this.$router.push("/timusys")
    }
  }
};
</script>