<template>
  <div>
    <div class="columns">
      <div class="column is-10 is-offset-1">
        <Navbar></Navbar>
        <div class="card">
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <figure class="image is-48x48">
                  <img src="../assets/head.png" alt="Placeholder image" />
                </figure>
              </div>
              <div class="media-content">
                <p class="title is-4">ZHJDJIE</p>
                <p class="subtitle is-6">909092921@qq.com</p>
              </div>
            </div>

            <div class="content">
              Bit Bit Bit
              <br />
              <time datetime="2016-1-1">2021 年 1 月 1 日</time>
            </div>
            <!-- <div class="card-image"> -->
              <figure class="image is-16by9">
                <img src="../assets/img1.jpg" alt="Placeholder image" />
              </figure>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-10 is-offset-5">
        <a style="color:black" v-on:click="openbeian">滇ICP备20005369号</a>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
export default {
  name: "Home",
  components: {
    Navbar,
  },
  methods:{
    openbeian(){
 window.open('https://beian.miit.gov.cn/')
    }
  }
};
</script>
